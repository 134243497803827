import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useMemo, useRef, useState } from "react";
import { addToPlaylist, createAddPlaylists } from "../api/playlist";
import { NotePrivacy } from "../types/constants";
import { pbpVideoDto } from "../types/dto";
import { getCurrentBrowserTime } from "../utils/date-time";
import { useAuth } from "../context/auth-context";
import AddIcon from '@mui/icons-material/Add';
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';
import { getGameTextNoSpoilers, getSplitQuery } from "../utils/game-utils";
import { useDataContext } from "../context/nba-data";
import Button from "@mui/material/Button";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import Box from "@mui/material/Box";
import { textOverflowEllipsisSx } from "../common/css-constants";
import { useUserDataStore } from "../context/playlist-store";

interface savePlaylistButtonProps {
    onClose?: (() => void),
    button: ReactJSXElement,
    pbp: pbpVideoDto
}
const menuItemPaddingX = '10px;';
const menuItemPaddingY = '3px;';
export default function SavePlaylistButton(props: savePlaylistButtonProps) {
    const {userPlaylists, refreshUserPlaylists} = useUserDataStore.getState();

    const auth = useAuth();
    const data = useDataContext();

    const [userSearch, setUserSearch] = useState("");

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (auth.userId !== null) setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };
    const handleClose = () => {
        if (props.onClose) props.onClose();
        setAnchorEl(null);
    };

    const toAdd = useMemo(() => {
        const { pbp } = props;
        return pbp?.pbpVideoId;
        // return { pbpVideoId: pbp?.pbpVideoId, url: pbp?.videoUrl };
    }, [props.pbp]);

    const createAddToPlaylist = async () => {
        handleClose();
        const { pbp } = props;
        const game = data.gamesBySeason[pbp.season].filter(x => x.gameId === pbp.gameId)[0];
        const title = getGameTextNoSpoilers(game, data.teams)
        await createAddPlaylists(title, '', NotePrivacy.Private, [toAdd], null, null)
        await refreshUserPlaylists(auth.userId);
    };

    const onAddToPlaylist = async (playlistId: string) => {
        handleClose();
        await addToPlaylist(
            playlistId,
            toAdd,
        );
    };

    const filteredPlaylists = useMemo(() => {
        return userPlaylists.playlists?.filter(playlist => {
            if (userSearch.length < 2) {
                return true;
            } else {
                const splitQuery = getSplitQuery(userSearch.toLocaleLowerCase());
                return splitQuery.every(queryPart => {
                    const additionalTerms = [] as string[];
                    if (playlist.teamId) {
                        const team = data.teams[playlist.teamId];
                        additionalTerms.push(team.teamCity.toLocaleLowerCase(), team.teamName.toLocaleLowerCase(), team.triCode.toLocaleLowerCase());
                    }
                    if (playlist.playerId) {
                        const player = data.allPlayers.find(p => p.playerId === playlist.playerId);
                        if (player) additionalTerms.push(player.name.toLocaleLowerCase());
                    }
                    return playlist.title.toLocaleLowerCase().includes(queryPart) ||
                        additionalTerms.some(term => term.includes(queryPart))
                })
            }
        })

    }, [userSearch, userPlaylists.playlists]);

    const stopPropagation = (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case "ArrowDown":
            case "ArrowUp":
            case "Home":
            case "End":
                break;
            default:
                e.stopPropagation();
        }
    };
    return (
        <>
            <Box onClick={handleClick}>
                {props.button}
            </Box>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    sx: { paddingY: menuItemPaddingY },
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem sx={{ paddingX: '5px', paddingY: menuItemPaddingY }}>
                    <TextField
                        value={userSearch}
                        onKeyDown={stopPropagation}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setUserSearch(event.target.value);
                        }}
                        placeholder='Find playlist'
                        InputProps={{
                            sx: { paddingX: '6px', paddingY: 0 },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ paddingLeft: '0px' }} />
                                </InputAdornment>
                            ),
                        }}
                        color='secondary'
                        size='small'
                        variant="outlined"
                    />
                </MenuItem>
                <MenuItem onClick={createAddToPlaylist} sx={{ paddingX: menuItemPaddingX, paddingY: menuItemPaddingY, borderBottom: '1px solid darkgray' }}>
                    <AddIcon />&nbsp;New Playlist
                </MenuItem>
                {
                    filteredPlaylists.map((playlist, i) => {
                        let tags = [] as string[];
                        if (playlist.teamId) {
                            const team = data.teams[playlist.teamId];
                            tags.push(team.triCode.toLocaleUpperCase())
                        }
                        if (playlist.playerId) {
                            const foundPlayer = data.allPlayers.find(p => p.playerId === playlist.playerId);
                            if (foundPlayer) tags.push(foundPlayer.name);
                        }
                        return (
                            <MenuItem key={`playlistButton${i}`} onClick={() => onAddToPlaylist(playlist.playlistId)}
                                sx={{ paddingX: menuItemPaddingX, paddingY: menuItemPaddingY, maxWidth: '250px', fontSize: 14 }}>
                                <div style={textOverflowEllipsisSx}>
                                    {
                                        `${playlist.title}${tags.length > 0 ?
                                            " (" + tags.join(', ') + ")" : ''}`
                                    }
                                </div>
                            </MenuItem>
                        )
                    })
                }
            </Menu>
        </>
    )

}