import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { useCallback, useEffect, useRef, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { getFeed } from "../api/feed";
import { playerUI } from "../api/nba";
import { getNotesData } from "../api/pbp-user";
import { BOTTOM_NAV_SIZE_PX, calculateMaxVideoSize } from "../common/css-constants";
import { useDataContext } from "../context/nba-data";
import { FollowFilter } from "../types/constants";
import { feedDto, feedItem, noteAndPbpDto, playlistHeavyDto, teamsDto } from "../types/dto";
import { getCombinedSortedFeed, isItemPlaylist } from "../utils/feed-utils";
import ClipView from "./ClipView";
import PlaylistClipView from "./PlaylistClipView";
import PlayersSelector from "./menu/PlayersSelector";
import TeamSelector from "./menu/TeamSelector";


interface clipFeedProps {
  showPrivacyFilters: boolean;
  showContentFilters: boolean;
  getNotesParams: getNotesData;
}

enum FilterType {
  TEAM = "Team",
  PLAYER = "Player",
}

export default function ClipFeed(props: clipFeedProps) {
  // const [notesPlaylists, setNotesPlaylists] = useState([] as feedItem[]);
  const [feed, setFeed] = useState({
    notesPlaylists: [],
    playlists: [],
    notes: [],
    notesById: {},
    pbpVideoById: {},
  } as feedDto & { notesPlaylists: feedItem[] });
  const virtuosoRef = useRef<HTMLDivElement>(null);
  const [distance, setDistance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [canLoadMore, setCanLoadMore] = useState(true);

  const [selectedPlayers, _setSelectedPlayers] = useState([] as playerUI[]);
  const [selectedTeams, _setSelectedTeams] = useState([] as teamsDto[]);

  const setSelectedPlayers = (players: playerUI[]) => {
    _setSelectedTeams([]);
    _setSelectedPlayers(players);
  };

  const setSelectedTeams = (teams: teamsDto[]) => {
    _setSelectedPlayers([]);
    _setSelectedTeams(teams);
  };

  const [followFilter, _setFollowFilter] = useState(FollowFilter.ALL);
  const setFollowFilter = (ut: FollowFilter) => {
    if (ut) _setFollowFilter(ut);
  };

  const [filterType, _setFilterType] = useState(FilterType.TEAM);
  const setFilterType = (ft: FilterType) => {
    if (ft) _setFilterType(ft);
  };

  const getSetFeed = (isNewFetch: boolean) => {
    setLoading(true);
    const offsetToUse = isNewFetch ? 0 : offset;
    const data = { ...props.getNotesParams, followFilter, offset: offsetToUse };
    if (selectedPlayers.length > 0) data.playerId = selectedPlayers[0].playerId;
    if (selectedTeams.length > 0) data.teamId = selectedTeams[0].teamId;
    getFeed(data).then(res => {
      if (!(res as any).error) {
        const notesPlaylists = getCombinedSortedFeed(res);
        setOffset(offset + notesPlaylists.length);
        setCanLoadMore(notesPlaylists.length >= 50);
        if (isNewFetch) {
          setFeed({...res, notesPlaylists});
        }
        else {
          setFeed({
            notesPlaylists,
            playlists: [...feed.playlists, ...res.playlists],  // Merge arrays
            notes: [...feed.notes, ...res.notes],  // Merge arrays
            notesById: { ...feed.notesById, ...res.notesById }, // Merge objects
            pbpVideoById: { ...feed.pbpVideoById, ...res.pbpVideoById }, // Merge objects
          });
        }
      }
    }).finally(() => setLoading(false));
  }

  useEffect(() => {
    const element = virtuosoRef.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      const distanceToViewportTop = rect.top + window.scrollY;
      setDistance(distanceToViewportTop);
    }
    getSetFeed(true);
  }, [followFilter, selectedPlayers, selectedTeams, props.getNotesParams]);

  const loadMore = useCallback(() => {
    if (canLoadMore) getSetFeed(false)
  }, [feed])
  
  const data = useDataContext();

  const theme = useTheme();
  const navBarShown = useMediaQuery(theme.breakpoints.down("md"));
  const navBarSize = navBarShown ? BOTTOM_NAV_SIZE_PX : 0;
  return (
    <>
      <Grid container margin="auto" maxWidth={calculateMaxVideoSize() + "px"}>
        {
          props.showPrivacyFilters &&
          <Grid item xs={12} marginBottom='8px'>
            <Box sx={{ marginBottom: 1, borderBottom: 1, borderColor: 'divider', textColor: 'white' }}>
              <Tabs variant='fullWidth' textColor='inherit' value={followFilter} onChange={(e, val) => setFollowFilter(val)}>
                <Tab label={FollowFilter.ALL} value={FollowFilter.ALL} />
                <Tab label={FollowFilter.FOLLOWING} value={FollowFilter.FOLLOWING} />
              </Tabs>
            </Box>
          </Grid>
          // <Grid item xs={12} textAlign="center">
          //   <ToggleButtonGroup
          //     sx={{ margin: "auto", marginBottom: "10px" }}
          //     color="secondary"
          //     value={followFilter}
          //     size="small"
          //     exclusive
          //     onChange={(e, newType) => setFollowFilter(newType)}
          //   >
          //     <ToggleButton value={FollowFilter.ALL}>
          //       {FollowFilter.ALL}
          //     </ToggleButton>
          //     <ToggleButton value={FollowFilter.FOLLOWING}> 
          //       {FollowFilter.FOLLOWING}
          //     </ToggleButton>
          //     {/* <ToggleButton value={FollowFilter.FRIENDS}>
          //     {FollowFilter.FRIENDS}
          //   </ToggleButton> */}
          //   </ToggleButtonGroup>
          // </Grid>
        }
        {props.showContentFilters && (
          <>
            {" "}
            <Grid item xs={4} textAlign="right">
              <ToggleButtonGroup
                sx={{ margin: "auto" }}
                color="secondary"
                value={filterType}
                size="small"
                exclusive
                fullWidth
                onChange={(e, newType) => setFilterType(newType)}
              >
                <ToggleButton value={FilterType.TEAM}>
                  {FilterType.TEAM}
                </ToggleButton>
                <ToggleButton value={FilterType.PLAYER}>
                  {FilterType.PLAYER}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={8} paddingLeft='10px'>
              {filterType === FilterType.TEAM && (
                <TeamSelector
                  selectedTeams={selectedTeams}
                  maxItems={1}
                  label="Team"
                  onChange={setSelectedTeams}
                />
              )}
              {filterType === FilterType.PLAYER && (
                <PlayersSelector
                  showAbbrs={false}
                  players={data.allPlayers}
                  selectedPlayers={selectedPlayers}
                  maxItems={1}
                  label="Player"
                  onChange={setSelectedPlayers}
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
      <Grid container>
        <Grid item ref={virtuosoRef} xs={12}>
          {/* <IconButton color='secondary' onClick={() => setOpen(true)}>
                <HelpIcon />
            </IconButton> */}
          {feed.notesPlaylists.length === 0 ? (
            loading ? (
            <Typography variant="h6" textAlign="center" marginTop="10px">
              Loading...
            </Typography>
          ) : (
            <Typography variant="h6" textAlign="center" marginTop="10px">
              No posts to display
            </Typography>
          )) : (
            <Virtuoso
              data={feed.notesPlaylists}
              endReached={loadMore}
              style={{
                height: `calc(100vh - ${distance + navBarSize}px)`,
                margin: "auto",
              }}
              itemContent={(i, notePlaylist) => {
                if (isItemPlaylist(notePlaylist)) {
                  const playlist = notePlaylist as playlistHeavyDto;
                  return <PlaylistClipView
                    isSingleItemView={false}
                    loadPlaylistDetails={true}
                    key={`${playlist.playlistId}`}
                    playlist={playlist}
                    startingPbpVideoId={null}
                    notesById={feed?.notesById[playlist.userId] ?? {}}
                    pbpVideoById={feed?.pbpVideoById ?? {}} />
                } else {
                  const note = notePlaylist as noteAndPbpDto;
                  return <ClipView
                    isSingleItemView={false}
                    key={`${note.pbpVideoId}${note.userId}`}
                    note={note}
                    pbpVideo={note.pbpVideo}
                  />
                }
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
