import { Season } from "../types/constants";
import { gameBoxDto, playerBoxDto } from "../types/dto";
import { STAT_PREFIX, apiGetUnhandledErrors } from "./utils";

export const getGameBox = async (season: Season, gameId: number) => {
    const response = await apiGetUnhandledErrors(`${STAT_PREFIX}/gamebox?season=${season}&gameId=${gameId}`);
    if (response.status === 404) return null;
    const res: gameBoxDto = await response.json();
    return res;
};

export const getPlayerBox = async (season: Season, playerId: number) => {
    const response = await apiGetUnhandledErrors(`${STAT_PREFIX}/playerbox?season=${season}&playerId=${playerId}`);
    if (response.status === 404) return null;
    const res: playerBoxDto = await response.json();
    return res;
};

