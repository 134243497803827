import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/auth-context";
import { followsDto } from "../types/dto";
import { createUserProfilePath } from "../utils/url-utils";
import FollowButton from "./FollowButton";

enum FollowType {
  FRIENDS = "Friends",
  FOLLOWERS = "Followers",
  FOLLOWS = "Follows",
}

interface followListProps {
  follows: followsDto;
}

export default function FollowList(props: followListProps) {
  const [followType, _setFollowType] = useState(FollowType.FOLLOWERS);
  const setFollowType = (ft: FollowType) => {
    if (ft) _setFollowType(ft);
  };
  const userAuth = useAuth();

  const filteredFollows = props.follows.userFollows.filter(
    (userFollow) =>
      (FollowType.FRIENDS === followType &&
        userFollow.followed &&
        userFollow.follows) ||
      (FollowType.FOLLOWERS === followType && userFollow.followed) ||
      (FollowType.FOLLOWS === followType && userFollow.follows),
  );

  return (
    <Grid container mt={"10px"} width="100%">
      <ToggleButtonGroup
        sx={{ margin: "auto" }}
        color="secondary"
        value={followType}
        size="small"
        exclusive
        onChange={(e, newType) => setFollowType(newType)}
      >
        <ToggleButton value={FollowType.FRIENDS}>
          {FollowType.FRIENDS}
        </ToggleButton>
        <ToggleButton value={FollowType.FOLLOWERS}>
          {FollowType.FOLLOWERS}
        </ToggleButton>
        <ToggleButton value={FollowType.FOLLOWS}>
          {FollowType.FOLLOWS}
        </ToggleButton>
      </ToggleButtonGroup>
      <Grid xs={12} item>
        <List>
          {filteredFollows.map((userFollow, i) => {
            const userProfilePath = createUserProfilePath(userFollow.userId);
            return (
              <ListItem sx={{ borderBottom: "1px solid gray" }} key={'userFollow' + i}>
                <ListItemText
                  primary={
                    <Link style={{ color: "white" }} to={userProfilePath}>
                      {userFollow.username}
                    </Link>
                  }
                  //   secondary='Description here'
                />
                <FollowButton
                  onClick={(follows: boolean) =>
                    (userFollow.loggedInFollows = follows)
                  }
                  loggedInUserId={userAuth.userId}
                  followedUserId={userFollow.userId}
                  userFollows={userFollow.loggedInFollows}
                  userCanFollow={userFollow.userCanFollow}
                />
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
}
