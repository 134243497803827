import { useEffect, useState } from "react";
import ClipFeed from "../components/ClipFeed";
import { getNotifs, markNotifsRead } from "../api/auth";
import { notifDto } from "../types/dto";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { NotifInteractionType, NotifItemType } from "../types/constants";
import CommentIcon from "@mui/icons-material/Comment";
import FavoriteIcon from "@mui/icons-material/Favorite";
import UserPostHeader from "../components/UserPostHeader";
import Typography from "@mui/material/Typography";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { createClipPath, createReplyPath, createUserProfilePath } from "../utils/url-utils";
import Grid from "@mui/material/Grid";
import { useUserDataStore } from "../context/playlist-store";
import { useAuth } from "../context/auth-context";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

interface notificationsProps { }

const renderNotification = (x: notifDto, navigate: NavigateFunction ) => {
  const isUnread = x.readEpochMs === '0';
  // {x.itemType === NotifItemType.Follow && ''}
  // {x.itemType === NotifItemType.Post && ''}
  // {x.itemType === NotifItemType.Reply && ''}          
  let icon = <CommentIcon sx={{marginRight: '8px'}} />;
  if (x.interactionType === NotifInteractionType.Like) icon = <FavoriteIcon sx={{marginRight: '8px'}} />
  if (x.interactionType === NotifInteractionType.Follow) icon = <FavoriteIcon sx={{marginRight: '8px'}} /> // TODO

  const userIds = Object.keys(x.interactionUserIds);
  const firstUserId = Number(userIds[0]);

  let actionFragment = 'replied to you:'
  if (x.interactionType === NotifInteractionType.Like) actionFragment = `liked your ${x.itemType === NotifItemType.Post ? 'post' : 'reply'}:`;
  if (x.interactionType === NotifInteractionType.Follow) actionFragment = 'followed you';

  const userProfilePath = createUserProfilePath(firstUserId);
  let rowClickUrl = userProfilePath;
  if (x.interactionType === NotifInteractionType.Like) {
    if (x.itemType === NotifItemType.Post)
      rowClickUrl = createClipPath(x.accountUserId, Number(x.itemId));
    else if (x.itemType === NotifItemType.Reply) {
      rowClickUrl = createReplyPath(x.itemId);
    }
  }
  if (x.interactionType === NotifInteractionType.Reply) {
    rowClickUrl = createReplyPath(x.itemId);
  };
  return <ListItem key={x.notificationId} disablePadding sx={{paddingY: '5px', border: '1px solid rgba(255, 255, 255, 0.12)'}}>
    <ListItemButton onClick={() => navigate(rowClickUrl)} sx={{padding: '0px', backgroundColor:isUnread ? '#1d1d1d' : 'inherit'}}>
      <Grid container sx={{padding: '5px'}}>
        <Grid item xs={12}>
          {icon}
          <Typography  variant="body1" component='span' sx={{ verticalAlign: "top" }}>
            &nbsp;
            <Link to={userProfilePath} onClick={e => e.stopPropagation()} style={{ color: "white" }}>
              {x.interactionUserIds[firstUserId]}
            </Link>&nbsp;
            {userIds.length > 1 ? `and ${userIds.length - 1} more ` : ''}
            {actionFragment}
          </Typography>
        </Grid>
        {
          x.interactionType !== NotifInteractionType.Follow && <Grid item xs={12} marginTop='5px'>
            <Typography variant="body1" color='dimgray' sx={{ verticalAlign: "center" }}>
              {x.itemContent}
            </Typography>
          </Grid>
        }
      </Grid>
    </ListItemButton>
  </ListItem>
}

export default function Notifications(props: notificationsProps) {
  const [notifs, setNotifs] = useState([] as notifDto[]);
  const refreshNotifCount =  useUserDataStore((state) => state.refreshNotifCount);
  const userAuth = useAuth();
  useEffect(() => {
   getNotifs().then(async x => {
    await setNotifs(x.notifications);
  }); 
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    const unreadNotifIds = notifs.filter(x => x.readEpochMs === '0').map(x => x.notificationId);
    if (unreadNotifIds.length > 0) {
      const timeout = setTimeout(async () => {
        await markNotifsRead(unreadNotifIds)
        refreshNotifCount(userAuth.userId);
      }, 5000);
      return () => clearTimeout(timeout)
    }
  }, [notifs]);

  return (
    <Container maxWidth='md'>
    <Typography variant='h5' margin='10px'>Notifications</Typography>
    <List>
      {
        notifs.map((x, i) => renderNotification(x, navigate))
      }
    </List>
    </Container>
  );
}
