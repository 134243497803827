import FavoriteIcon from "@mui/icons-material/Favorite";
import { Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';


interface likeIconProps {
    userLiked: boolean;
    likesCount: number;
    onLikeClick: () => void;
}
export const LikeIcon = (props: likeIconProps) => {
    return (
        <IconButton
            aria-label="Favorite"
            sx={{ color: props.userLiked ? "tomato" : "inherit" }}
            size="small"
            onClick={e => {
                e.stopPropagation();
                props.onLikeClick()
            }}
        ><FavoriteIcon sx={{ fontSize: "inherit" }} />
            <Typography variant='body1' component='span'>
                &nbsp;
                {props.likesCount}
            </Typography>
        </IconButton>
    )
}