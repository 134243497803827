import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import {
    createUserProfilePath
} from "../utils/url-utils";
interface userPostHeaderProps {
    username: string;
    userId: number;
    itemDate: Date;
}
export default function UserPostHeader(props: userPostHeaderProps) {
    const userProfilePath = createUserProfilePath(props.userId);
    return (
        <Typography onClick={e => e.stopPropagation()} variant="body1" sx={{ verticalAlign: "center" }}>
            <Link to={userProfilePath} style={{ color: "white" }}>
                {props.username}
            </Link>
            <Typography variant="caption">
                &nbsp;&nbsp;{DateTime.fromJSDate(new Date(props.itemDate)).toRelative()}
            </Typography>
        </Typography>
    );
}
