import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getNotes, getReplyNotes } from "../api/pbp-user";
import { MAX_POST_SIZE_PX } from "../common/css-constants";
import ClipView from "../components/ClipView";
import Replies from "../components/Replies";
import { noteAndPbpDto } from "../types/dto";
import { CLIP_PBP, CLIP_USER, REPLY } from "../utils/url-utils";

interface clipReplyProps {}

export default function ClipReply(props: clipReplyProps) {
  const [loaded, setLoaded] = useState(false);
  const [note, setNote] = useState(null as null | noteAndPbpDto);
  const [searchParams, setSearchParams] = useSearchParams();
  const replyId = searchParams.get(REPLY);
  useEffect(() => {
    if (replyId) {
      getReplyNotes(replyId).then(
        (res) => {
          setLoaded(true);
          if (res.notes.length > 0) setNote(res.notes[0]);
        },
      );  
    } else {
      setLoaded(true);
    }
  }, []);
  let content = null;

  if (!loaded) content = <Typography variant="body2">Loading...</Typography>;
  else if (note === null)
    content = <Typography variant="body2">Post doesn't exist</Typography>;
  else content = <>
    <ClipView isSingleItemView={true} note={note} pbpVideo={note.pbpVideo} />
    {
      note && <Container sx={{ margin: 'auto', maxWidth: `${MAX_POST_SIZE_PX + 50}px !important` }}>
        <Replies userId={note.userId} pbpVideoId={note.pbpVideoId} note={note} includeReplyId={replyId} />
      </Container>
    }
  </>;
  return content;
}
