import { Grid, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useEffect, useMemo, useRef, useState } from "react";
import ModalBase from "./ModalBase";

import { Link } from "react-router-dom";
import { createReply } from "../api/reply";
import { replyDto } from "../types/dto";
import { createUserProfilePath } from "../utils/url-utils";
import UserPostHeader from "./UserPostHeader";

interface replyModal {
    content: string;
    userId: number;
    username: string;
    date: Date;
    pbpVideoId: number;
    parentReplyId: string | null;
    rootReplyId: string | null;
    button: JSX.Element;
    onReply: (reply: replyDto) => void;
}

export default function ReplyModal(props: replyModal) {
    const [reply, setReply] = useState('');
    const [open, _setOpen] = useState(false);
    const textFieldRef = useRef<HTMLDivElement>(null);

    const clearState = () => {
        setReply('');
    }

    const setOpen = (newVal: boolean) => {
        if (!newVal) clearState();
        _setOpen(newVal);
        if (newVal) {
            textFieldRef.current?.focus();
        }
    }

    useEffect(() => {
        if (open) {
            setTimeout(() => textFieldRef.current?.focus(), 100);
        }
    }, [open]);

    const onSave = async () => {
        if (reply.trim().length < 1) alert('Must write something');
        else {
            const res = await createReply({
                pbpVideoId: props.pbpVideoId,
                accountUserId: props.userId,
                parentReplyId: props.parentReplyId,
                rootReplyId: props.rootReplyId,
                note: reply
            });
            props.onReply(res);
            setOpen(false);
        }
        return true;
    }

    const windowWidth = useMemo(() => window.innerWidth, [window.innerWidth]);

    return (
        <ModalBase
            open={open}
            setOpen={setOpen}
            content={
                <>
                    <Grid container maxWidth='100%' width={windowWidth}>
                        <Grid item xs={12} marginLeft='5px'>
                            <UserPostHeader username={props.username} userId={props.userId} itemDate={props.date} />
                        </Grid>
                        <Grid item xs={12} marginY='5px' paddingBottom='8px' borderBottom='1px solid gray'>
                            <Typography variant='body1'>
                                {props.content}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} marginTop='8px'>
                            <Typography variant='body2' marginLeft='5px'>
                                Replying to <Link to={createUserProfilePath(props.userId)} style={{ color: "white" }}>
                                    {props.username}
                                </Link>:
                            </Typography>
                            <TextField
                                inputRef={textFieldRef}
                                // onKeyDown={stopPropagation}
                                multiline
                                // label={'Reply'}
                                value={reply}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setReply(event.target.value);
                                }}
                                fullWidth
                                placeholder='Write your reply'
                                color='secondary'
                                size='small'
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </>
            }
            button={props.button}
            actions={
                <>
                    <Button color="secondary" onClick={e => {
                        e.stopPropagation();
                        _setOpen(false);
                    }}>
                        Close
                    </Button>
                    <Button onClick={e => {
                        e.stopPropagation();
                        onSave();
                    }} variant='contained' color="secondary">
                        Save
                    </Button>
                </>

            }
        />
    );
}
