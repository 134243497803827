import FavoriteIcon from "@mui/icons-material/Favorite";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from '@mui/material/CardHeader';
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from '@mui/material/Typography';
import { DateTime } from "luxon";
import { useState } from "react";
import { Link } from 'react-router-dom';
import { MAX_POST_SIZE_PX } from '../common/css-constants';
import NoteText from "../components/NoteText";
import VideoPlayer from "../components/VideoPlayer";
import { noteAndPbpDto, noteDto, pbpVideoDto } from "../types/dto";
import {
  createUserProfilePath
} from "../utils/url-utils";
import CommentIcon from '@mui/icons-material/Comment';

interface clipViewProps {
  note: noteAndPbpDto | noteDto;
  pbpVideo: pbpVideoDto;
  watchOnCsLink: string;
}
export default function ClipView(props: clipViewProps) {
  const note = props.note;
  const pbpVideo = props.pbpVideo;
  const [noteText, _setNoteText] = useState(note.note);

  // const data = useDataContext();
  // const allGames = data.gamesBySeason[note.seasonYear];
  // const game = allGames.find((game) => game.gameId === pbpVideo.gameId);
  const userProfilePath = createUserProfilePath(note.userId);
  const dt = DateTime.fromJSDate(new Date(note.noteUpdateUtc));
  const formattedTimeAgo = dt.toRelative();
  const border = "1px solid grey";

  return (
    <Card
      sx={{
        // maxWidth: calculateMaxVideoSize(),
        padding: '10px', 
        // margin: "10px",
        // background: "none",
        // borderBottom: border,
        borderRadius: "5px",
      }}
    >
      <CardHeader
        sx={{ pl: '10px', pr: '10px', pt: "10px", pb: "10px" }}
        // avatar={
        //   <Link
        //     to={userProfilePath}
        //     style={{ color: "white", textDecoration: "none" }}
        //   >
        //     {/* <Avatar>{note.username}</Avatar> */}
        //   </Link>
        // }
        title={
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Typography variant="body1" sx={{ verticalAlign: "middle" }}>
              <Link to={userProfilePath} target='_blank' style={{ color: 'black' }}>
                {note.username}
              </Link>
              &nbsp;&nbsp;
              <Link to={props.watchOnCsLink} target='_blank' style={{ textDecoration: 'none', color: 'black' }}>
                <Typography variant="caption" >
                  {formattedTimeAgo}
                </Typography>
              </Link>
            </Typography>
            <Link to={props.watchOnCsLink} target='_blank' style={{ flexGrow: 1, textDecoration: 'none', color: 'black' }}>
              <Box sx={{ textAlign: 'right' }}>
                <Typography variant="body1" component='span' sx={{ fontSize: '1.1rem', lineHeight: '25px', verticalAlign: "top" }}>
                  Watch on&nbsp;
                </Typography>
                <Box
                  component="img"
                  sx={{
                    height: 25,
                    // width: 350,
                    // maxHeight: { xs: 233, md: 167 },
                    // maxWidth: { xs: 170, md: 350 },
                  }}
                  src="/ICON_TRANSPARENT.png"
                />
              </Box>
            </Link>
          </Grid>
        }
      />
      <>
        <CardContent sx={{ pb: 1, pt: 0, pl: 0, pr: 0 }}>
          <VideoPlayer
            autoplay={false}
            pauseTrigger={false}
            onEnded={() => { }}
            pbpVideoId={pbpVideo.pbpVideoId}
            videoUrl={pbpVideo.videoUrl}
            videoHeight={0}
            onVideoLoad={() => {         window.dispatchEvent(new Event('resize'))          }}
            onView={() => { }}
          />
          {/* <Box style={{ textAlign: "center" }}>
            <GameInfo
              showOverflow={true}
              pbp={pbpVideo}
              game={game as games}
              showClock={true}
              showDate={true}
            />
          </Box> */}
          {
            (noteText?.trim()) && <Box style={{ textAlign: "center", marginTop: '10px' }}>
              <NoteText
                isEmbed={true}
                isPlaylist={false}
                userLoggedIn={true}
                userIsAuthor={false}
                noteVal={noteText}
                onChange={() => { }}
              />
            </Box>
          }
        </CardContent>
        <CardActions sx={{ fontSize: "1.2rem", padding: 0, maxWidth: MAX_POST_SIZE_PX, margin: 'auto' }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container sx={{ justifyContent: "flex-end" }} gap="15px">
                {/* <IconButton aria-label="more" sx={{ fontSize: "1.2rem" }}>
                <MoreHorizIcon sx={{ fontSize: "inherit" }} />
              </IconButton> */}
                <Link to={props.watchOnCsLink} target='_blank'>
                  <IconButton
                    aria-label="Favorite"
                    sx={{ color: "tomato" }}
                    size="small"
                    onClick={() => { }}
                  >
                    <CommentIcon sx={{ fontSize: "inherit" }} />
                    &nbsp;{note.replies}
                  </IconButton>
                </Link>
                <Link to={props.watchOnCsLink} target='_blank'>
                  <IconButton
                    aria-label="Favorite"
                    sx={{ color: "tomato" }}
                    size="small"
                    onClick={() => { }}
                  >
                    <FavoriteIcon sx={{ fontSize: "inherit" }} />
                    &nbsp;{note.likes}
                  </IconButton>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </CardActions>
      </>
    </Card >

  );
}
