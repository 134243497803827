import { create } from 'zustand'
import { playlistMetaDto } from '../types/dto';
import { getUserPlaylists } from '../api/playlist';
import { getNotifCounts } from '../api/auth';

const defaultUserPlaylists={ playlists: [] } as playlistMetaDto;
interface UserDataStore {
    notifCount: number;
    refreshNotifCount: (userId: number | null) => void;
    userPlaylists: playlistMetaDto;
    refreshUserPlaylists: (userId: number | null) => void;
  }

export const useUserDataStore = create<UserDataStore>((set) => ({
    notifCount: 0,
    refreshNotifCount: async (userId: number | null) => {
        if (userId) {
            const count = await getNotifCounts();
            set({ notifCount: count.count })
        } else {
            set({ notifCount: 0 })
        }
    },
    userPlaylists: defaultUserPlaylists,
    refreshUserPlaylists: async (userId: number | null) => {
        if (userId) {
            const userPlaylists = await getUserPlaylists(0);
            set({ userPlaylists })
        } else {
            set({ userPlaylists: defaultUserPlaylists })
        }
    }
}));

