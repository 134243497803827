import deepPurple from "@mui/material/colors/deepPurple";
import yellow from "@mui/material/colors/yellow";
import { createTheme } from "@mui/material/styles";

const embedTheme = createTheme({
  palette: {
    mode: "light",
    primary: deepPurple,
    secondary: yellow,
  },
  typography: {
    fontFamily: `"Kanit", sans-serif`,
    // fontFamily: `"Inter Tight", sans-serif`,
    // fontFamily: `"Urbanist", sans-serif`,
    // fontFamily: `"Barlow Semi Condensed", sans-serif`,
    // fontFamily: `"Alumni Sans", sans-serif`,
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#2b2b2b",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            minHeight: 24,
            border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    },
  },
});

export default embedTheme;
