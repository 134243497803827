import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getNotes } from "../api/pbp-user";
import { getPlaylistDetails } from "../api/playlist";
import { noteAndPbpDto, playlistNotes } from "../types/dto";
import { CLIP_PBP, CLIP_USER, EMBED_CLIP_PBP, EMBED_CLIP_USER, EMBED_PLAYLIST_ID, EMBED_PLAYLIST_ITEM_ID, PLAYLIST_ID, PLAYLIST_ITEM_ID, createClipPathExternal, createPlaylistPathExternal } from "../utils/url-utils";
import ClipView from "./ClipView";
import PlaylistClipView from "./PlaylistClipView";
import {  ThemeProvider } from "@mui/material/styles";
import embedTheme from "../common/embed-theme";


interface clipProps { }

export default function Clip(props: clipProps) {
    const [loaded, setLoaded] = useState(false);
    const [note, setNote] = useState(null as null | noteAndPbpDto);
    const [playlist, setPlaylist] = useState(null as null | playlistNotes);
    const [searchParams, setSearchParams] = useSearchParams();
    const playlistId = searchParams.get(EMBED_PLAYLIST_ID);
    const isPlaylist = Boolean(playlistId);

    const userId = Number(searchParams.get(EMBED_CLIP_USER));
    const pbpVideoId = Number(searchParams.get(EMBED_CLIP_PBP));
    const pbpVideoIds = [pbpVideoId];
    const playlistItemQs = searchParams.get(EMBED_PLAYLIST_ITEM_ID);
    const playlistItem = playlistItemQs ? Number(playlistItemQs) : null;

    const watchOnCsLink = isPlaylist ? createPlaylistPathExternal(playlistId as string, playlistItem)
        : createClipPathExternal(userId, pbpVideoId);
    useEffect(() => {
        if (isPlaylist) {
            getPlaylistDetails(playlistId as string).then(res => {
                setLoaded(true);
                setPlaylist(res);
                window.dispatchEvent(new Event('resize'));
            })
        } else {
            getNotes({ userId, pbpVideoIds, orderByTime: true, offset: 0 }).then(
                (res) => {
                    setLoaded(true);
                    if (res.notes.length > 0) setNote(res.notes[0]);
                    window.dispatchEvent(new Event('resize'));
                },
            );
        }
    }, []);

    let content = null;
    if (!loaded)
        content = <Typography variant="body2">Loading...</Typography>;
    else if (isPlaylist) {
        if (playlist === null)
            content = <Typography variant="body2">Playlist doesn't exist</Typography>;
        else
            content = <PlaylistClipView
            watchOnCsLink={watchOnCsLink}
                loadPlaylistDetails={false}
                playlist={playlist.playlist}
                startingPbpVideoId={playlistItem}
                notesById={playlist.notesById}
                pbpVideoById={playlist.pbpVideoById} />;
    } else {
        if (note === null)
            content = <Typography variant="body2">Post doesn't exist</Typography>;
        else content = <ClipView watchOnCsLink={watchOnCsLink} note={note} pbpVideo={note.pbpVideo} />;
    }
    return (
        <ThemeProvider theme={embedTheme}>
            {content}
        </ThemeProvider>);
}



// interface playlistProps { }


// export default function Playlist(props: playlistProps) {

//     let content = null;
//     if (!loaded) content = <Typography variant="body2">Loading...</Typography>;
//     else if (playlist === null)
//         content =
//     else content = <PlaylistClipView
//         loadPlaylistDetails={false}
//         playlist={playlist.playlist}
//         startingPbpVideoId={playlistItem}
//         notesById={playlist.notesById}
//         pbpVideoById={playlist.pbpVideoById} />
//     return content;
// }
