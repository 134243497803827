import AddIcon from '@mui/icons-material/Add';
import FavoriteIcon from "@mui/icons-material/Favorite";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from "react";
import { likeNote, unlikeNote, upsertNote } from "../api/pbp-user";
import { getPlaylistDetails } from '../api/playlist';
import { MAX_POST_SIZE_PX } from '../common/css-constants';
import { useAuth } from "../context/auth-context";
import { useDataContext } from '../context/nba-data';
import { League, NotePrivacy } from "../types/constants";
import { noteDto, notesById, pbpVideoDto, playlistHeavyDto } from "../types/dto";
import { games } from '../types/ui';
import {
    createPlaylistPath,
    createPlaylistPathExternal
} from "../utils/url-utils";
import ClipPlaylistViewWrapper from "./ClipPlaylistViewWrapper";
import GameInfo from "./GameInfo";
import NotePrivacySelect from "./NotePrivacySelect";
import NoteText from "./NoteText";
import SavePlaylistButton from "./SavePlaylistButton";
import ShareMenu from "./ShareMenu";
import VideoPager from './VideoPager';
import VideoPlayer from "./VideoPlayer";
import CommentIcon from '@mui/icons-material/Comment';
import ReplyIcon from '@mui/icons-material/Reply';
import { LikeIcon } from './LikeIcon';
import ReplyModal from './ReplyModal';
import Container from '@mui/material/Container';
import Replies from './Replies';
import theme from '../common/theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const shouldSetVideoIndex = (index: number, pbp: any[]) => {
    if (index >= 0 && index <= pbp.length - 1) return true;
    return false;
};



interface playlistClipViewProps {
    isSingleItemView: boolean;
    playlist: playlistHeavyDto;
    notesById: notesById;
    pbpVideoById: { [key: number]: pbpVideoDto };
    loadPlaylistDetails: boolean;
    startingPbpVideoId: number | null;
}

export default function PlaylistClipView(props: playlistClipViewProps) {
    const { playlist } = props;
    let startIndex = 0;
    if (props.startingPbpVideoId) {
        const i = playlist.playlistItems.findIndex(x => x.pbpVideoId === props.startingPbpVideoId);
        if (i > 0) startIndex = i;
    }
    const [videoIndex, _setVideoIndex] = useState(startIndex);
    const [videoIndexDisplay, _setVideoIndexDisplay] = useState(0);

    const [notesById, setNotesById] = useState(props.notesById);
    const [pbpVideoById, setPbpVideoById] = useState(props.pbpVideoById);
    const vid = playlist.playlistItems[videoIndex];
    const note = notesById[vid.pbpVideoId] as noteDto | undefined;
    const pbpVideo = pbpVideoById[vid.pbpVideoId] as pbpVideoDto;
    const userAuth = useAuth();

    const [noteText, _setNoteText] = useState(note?.note ?? "");
    const [notePrivacy, _setNotePrivacy] = useState((note?.notePrivacy ?? userAuth.userDetails?.preferences.postPrivacy ?? NotePrivacy.Public) as NotePrivacy);
    const [isEdited, setIsEdited] = useState(false);
    const [userLiked, setUserLiked] = useState(note?.userLiked ?? false);
    const userId = userAuth?.userId ?? null;
    const userIsAuthor = userId === playlist.userId;

    const setNoteInfo = (toSet: noteDto | undefined) => {
        _setNoteText(toSet?.note ?? "");
        _setNotePrivacy((toSet?.notePrivacy ?? NotePrivacy.Public) as NotePrivacy);
        setUserLiked((toSet?.userLiked ?? false));
    }
    const setVideoIndex = (i: number) => {
        _setVideoIndex(i);
        _setVideoIndexDisplay(i);
        const newItem = playlist.playlistItems[i];
        const note = notesById[newItem.pbpVideoId];
        setNoteInfo(note);
        setIsEdited(false);
    };

    useEffect(() => setVideoIndex(0), [props.playlist])
    useEffect(() => {
        if (props.loadPlaylistDetails) {
            getPlaylistDetails(props.playlist.playlistId).then(res => {
                if (res) {
                    setPbpVideoById(res.pbpVideoById);
                    setNotesById(res.notesById);
                    const note = res.notesById[vid.pbpVideoId] as noteDto | undefined;
                    setNoteInfo(note);
                }
            })
        }
    }, []);
    const handleVideoChange = (i: number) => {
        if (shouldSetVideoIndex(i, playlist.playlistItems)) setVideoIndex(i);
    };
    const prevVideo = () => handleVideoChange(videoIndex - 1);
    const nextVideo = () => handleVideoChange(videoIndex + 1);

    const playlistPath = createPlaylistPathExternal(
        playlist.playlistId,
        vid.pbpVideoId
    );
    const setNoteText = (newText: string) => {
        setIsEdited(true);
        _setNoteText(newText);
    };
    const setNotePrivacy = (newPrivacy: NotePrivacy) => {
        setIsEdited(true);
        _setNotePrivacy(newPrivacy);
    };

    const onSubmitNote = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (pbpVideo) {
            upsertNote(
                userAuth.userId,
                League.NBA,
                pbpVideo.season,
                pbpVideo.gameId,
                pbpVideo.pbpVideoId,
                noteText,
                notePrivacy,
            ).then(res => {
                setIsEdited(false);
            });
        }
    };
    const userLoggedIn = userAuth.userId !== null;

    const onLikeClick = () => {
        if (userId && note && pbpVideo) {
            if (!userLiked) {
                likeNote(note.userId, pbpVideo.pbpVideoId, playlist.playlistId);
                setUserLiked(true);
                note.likes++;
                note.userLiked = true;
            } else {
                unlikeNote(note.userId, pbpVideo.pbpVideoId);
                setUserLiked(false);
                note.likes--;
                note.userLiked = false;
            }
        }
    };

    const data = useDataContext();

    let game: games | undefined;
    if (pbpVideo) {
        const allGames = data.gamesBySeason[pbpVideo.season];
        game = allGames.find((game) => game.gameId === pbpVideo.gameId);
    }

    let likesAdjust = 0;
    if (userLiked && note && userLiked !== note.userLiked) likesAdjust++;
    else if (!userLiked && note && userLiked !== note.userLiked) likesAdjust--;

    const greaterThanSmall = useMediaQuery(theme.breakpoints.up("sm"));
    return (
        <>
            <ClipPlaylistViewWrapper
                viewItemUrl={props.isSingleItemView ? null : createPlaylistPath(
                    playlist.playlistId,
                    pbpVideo.pbpVideoId
                )}
                username={playlist.username}
                ownerUserId={playlist.userId}
                userFollows={playlist.userFollows}
                userCanFollow={playlist.userCanFollow}
                itemDate={playlist.playlistUpdateUtc}
            >
                <>
                    <CardContent sx={{ pb: 1, pt: 0, pl: 0, pr: 0 }}>
                        <Box marginX='10px' marginBottom='5px' textAlign='left'>
                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                {playlist.title}
                            </Typography>
                            <Typography variant='body2'>
                                {playlist.note}
                            </Typography>
                        </Box>
                        <VideoPlayer
                            autoplay={true}
                            pauseTrigger={false}
                            onEnded={props.isSingleItemView ? () => { } : nextVideo}
                            pbpVideoId={vid.pbpVideoId}
                            videoUrl={vid.url}
                            videoHeight={0}
                            onVideoLoad={() => { }}
                            onView={() => { }}
                        />
                        <Grid container marginBottom='15px'>
                            <Grid item xs={6} textAlign='center' alignSelf='center'>
                                <VideoPager isPlaylist={true} videoIndex={videoIndex} maxIndex={playlist.playlistItems.length} onVideoIndexChange={handleVideoChange} />
                            </Grid>
                            <Grid item xs={3} paddingX='2px' onClick={e => e.stopPropagation()}>
                                <Button sx={{ paddingY: 0 }} onClick={prevVideo}
                                    disabled={videoIndex < 1} variant="contained" fullWidth>
                                    <SkipPreviousIcon />
                                    Prev
                                </Button>
                            </Grid>
                            <Grid item xs={3} paddingX='2px' onClick={e => e.stopPropagation()}>
                                <Button sx={{ paddingY: 0 }} onClick={nextVideo}
                                    disabled={videoIndex >= playlist.playlistItems.length - 1} variant="contained" fullWidth>
                                    Next
                                    <SkipNextIcon />
                                </Button>
                            </Grid>
                        </Grid>
                        {
                            pbpVideo && <Box style={{ textAlign: "center" }}>
                                <GameInfo
                                    showOverflow={true}
                                    pbp={pbpVideo}
                                    game={game as games}
                                    showClock={true}
                                    showDate={true}
                                />
                            </Box>
                        }
                        {
                            (userIsAuthor || noteText?.trim()) &&
                            <Box style={{ marginTop: '10px', textAlign: 'center' }}>
                                <NoteText
                                    isEmbed={false}
                                    isPlaylist={false}
                                    userLoggedIn={true}
                                    userIsAuthor={userIsAuthor}
                                    noteVal={noteText}
                                    onChange={setNoteText}
                                />
                            </Box>

                        }
                    </CardContent>
                    <CardActions sx={{ fontSize: "1.2rem", padding: 0, maxWidth: MAX_POST_SIZE_PX, margin: 'auto' }}>
                        <Grid container flexDirection={greaterThanSmall ? 'row-reverse' : 'row'} >
                            {
                                userIsAuthor &&
                                <Grid item xs={12} sm={4} md={3} textAlign={'right'} >
                                    <NotePrivacySelect
                                        privacy={notePrivacy}
                                        onChange={privacy => setNotePrivacy(privacy)}
                                    />
                                    <Button
                                        sx={{ visibility: userIsAuthor ? "" : "hidden" }}
                                        disabled={!isEdited}
                                        variant="contained"
                                        onClick={(e) => onSubmitNote(e)}
                                    >
                                        Edit
                                    </Button>
                                </Grid>
                            }
                            <Grid item xs={12} sm={userIsAuthor ? 8 : 12} md={userIsAuthor ? 9 : 12}>
                                <Grid container sx={{ justifyContent: "flex-end", margin: 'auto' }} gap="15px">
                                    {/* <IconButton aria-label="more" sx={{ fontSize: "1.2rem" }}>
                  <MoreHorizIcon sx={{ fontSize: "inherit" }} />
                </IconButton> */}
                                    {
                                        userLoggedIn && pbpVideo && <SavePlaylistButton pbp={pbpVideo}
                                            button={
                                                <IconButton
                                                    sx={{ fontSize: "1.2rem" }}
                                                >
                                                    <AddIcon sx={{ fontSize: "inherit" }} />
                                                    <Typography component='span' variant='body1' >
                                                        &nbsp;Playlist
                                                    </Typography>
                                                </IconButton>
                                            } />
                                    }
                                    {
                                        note?.note &&
                                        <>
                                            <ReplyModal
                                                content={note.note}
                                                userId={note.userId}
                                                username={note.username}
                                                date={note.noteUpdateUtc}
                                                pbpVideoId={note.pbpVideoId}
                                                parentReplyId={null}
                                                rootReplyId={null}
                                                onReply={() => { }}
                                                button={
                                                    <IconButton
                                                        aria-label="Reply"
                                                        size="small"
                                                    >
                                                        <ReplyIcon sx={{ fontSize: "inherit" }} />
                                                        <Typography component='span' variant='body1' >
                                                            &nbsp;Reply
                                                        </Typography>
                                                    </IconButton>}
                                            />
                                            <IconButton
                                                aria-label="Favorite"
                                                size="small"
                                            ><CommentIcon sx={{ fontSize: "inherit" }} />
                                                <Typography component='span' variant='body1' >
                                                    &nbsp;{note.replies}
                                                </Typography>
                                            </IconButton>
                                            <LikeIcon userLiked={userLiked} onLikeClick={onLikeClick} likesCount={note.likes + likesAdjust} />
                                        </>
                                    }
                                    {/* <ShareMenu
                                    playlistOrNoteCheck={playlist}
                                    note={note}
                                    playlist={playlist}
                                    pbpVideo={pbpVideo}
                                    playlistPbpVideos={playlistPbpVideos}
                                /> */}

                                </Grid>
                            </Grid>
                        </Grid>
                    </CardActions>
                </>
            </ClipPlaylistViewWrapper>
            {
                note && props.isSingleItemView && <Container sx={{ margin: 'auto', maxWidth: `${MAX_POST_SIZE_PX + 50}px !important` }}>
                    <Replies userId={playlist.userId} pbpVideoId={pbpVideo.pbpVideoId} note={note} includeReplyId={null} />
                </Container>
            }
        </>
    );
}