import { memo, useEffect, useMemo, useRef, useState } from "react";
import { IFRAME_BASE_URL, createIframeUrl } from "../utils/url-utils";



interface iFrameContentProps {
  height: number; // This prop will trigger re-renders
  videoUrl: string; // This prop will be ignored for re-renders
  innerRef: React.RefObject<HTMLIFrameElement>;
}

const iFrameContent: React.FC<iFrameContentProps> = ({height, innerRef, videoUrl}) => {
  const src = createIframeUrl(videoUrl, true);
  return (<iframe 
    ref={innerRef}
    height={`${height}px`} 
    width='100%'
  style={{ minHeight: '1px', border: 'none', display: 'block', flexGrow: 1, overflow: 'hidden'}} src={src}>
</iframe>)
} 

const MemoIframeContent = memo(iFrameContent);



interface videoBaseIframeProps {
  pauseTrigger: boolean;
  vidRef: React.RefObject<HTMLVideoElement>;
  onLoadedData: () => void;
  onPause: () => void;
  onPlay: () => void;
  onEnded: () => void;
  videoHeight: number;
  videoUrl: string;
  autoplay: boolean;
}


export default function VideoBaseIframe(props: videoBaseIframeProps) {
  const iframeRef = useRef<null | HTMLIFrameElement>(null);
  const containerRef = useRef<null | HTMLDivElement>(null);
  const [width, setWidth] = useState(1280);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (entries[0]) {
        setWidth(entries[0].contentRect.width);
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const handleIframeMessage = (event: MessageEvent<any>) => {
      // Ensure the message is from the expected origin
      if (event.origin !== IFRAME_BASE_URL) return;
      const { type, payload } = event.data;
      if (payload?.src !== props.videoUrl) return;
      if (type === "onLoadedData") {
        props.onLoadedData();
      } else if (type === "onPause") {
        props.onPause();
      } else if (type === 'onPlay') {
        props.onPlay();
      } else if (type === 'onEnded') {
        props.onEnded();
      }
    };

    // Listen for messages from the iframe
    window.addEventListener("message", handleIframeMessage);

    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, [props.videoUrl]);

  useEffect(() => {
    iframeRef.current?.contentWindow?.postMessage(
      { type: "pauseTrigger" },
      IFRAME_BASE_URL
    );
  }, [props.pauseTrigger]);
  const height = Math.min(720, Math.ceil(720 * (width / 1280)));

  return (
    <div style={{width: '100%', maxWidth: '1280px', margin: 'auto'}} ref={containerRef}>
    <MemoIframeContent innerRef={iframeRef} height={height} videoUrl={props.videoUrl} />
    {/* <iframe ref={iframeRef} height={`${height}px`} width='100%'
      style={{minHeight: `${props.videoHeight}px`, border: 'none', display: 'block', flexGrow: 1, overflow: 'hidden'}} src={src}>

    </iframe> */}
    </div>

  )
}