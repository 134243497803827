import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import * as React from "react";

import TextField from "@mui/material/TextField";

import useMediaQuery from "@mui/material/useMediaQuery";


import Typography from "@mui/material/Typography";
import { login } from "../api/auth";

interface authFieldsProps {
  authType: string;
  apiMsg: string | null;
  verifyEmailMsg: boolean;
  onSubmit: (
    username: string,
    email: string,
    password: string,
    verifyPassword: string,
  ) => void;
  showEmail: boolean;
  showUsername: boolean;
  showPassword: boolean;
  showVerifyPassword: boolean;
}

const defaultProps = {
  showEmail: false,
  showUsername: false,
  showPassword: false,
  showVerifyPassword: false,
  verifyEmailMsg: false,
};
export default function AuthFields(props: authFieldsProps) {
  const isScreenBig = useMediaQuery("(min-width:600px)");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get("username") as string;
    const email = data.get("email") as string;
    const password = data.get("password") as string;
    const verifyPassword = data.get("verifyPassword") as string;
    await props.onSubmit(username, email, password, verifyPassword);
  };
  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit}
      sx={{ mt: 2, width: "100%" }}
    >
      <Grid container spacing={2}>
        {props.showEmail && (
          <Grid item xs={12}>
            <TextField
              size={isScreenBig ? "medium" : "small"}
              required
              color="secondary"
              fullWidth
              id="email"
              label="Email Address"
              type="email"
              name="email"
              autoComplete="email"
            />
          </Grid>
        )}
        {props.showUsername && (
          <Grid item xs={12}>
            <TextField
              size={isScreenBig ? "medium" : "small"}
              required
              color="secondary"
              fullWidth
              id="username"
              label="Username"
              type="username"
              name="username"
              autoComplete="username"
            />
          </Grid>
        )}
        {props.showPassword && (
          <Grid item xs={12}>
            <TextField
              size={isScreenBig ? "medium" : "small"}
              required
              color="secondary"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
            />
          </Grid>
        )}
        {props.showVerifyPassword && (
          <Grid item xs={12}>
            <TextField
              size={isScreenBig ? "medium" : "small"}
              required
              color="secondary"
              fullWidth
              name="verifyPassword"
              label="Verify Password"
              type="password"
              id="verifyPassword"
              autoComplete="off"
              onPaste={(e) => e.preventDefault()}
            />
          </Grid>
        )}
        {props.apiMsg !== null && (
          <Grid item xs={12}>
            <Typography variant="body2">{props.apiMsg}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => login}
          >
            {props.authType}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
AuthFields.defaultProps = defaultProps;
