import AddIcon from '@mui/icons-material/Add';
import FavoriteIcon from "@mui/icons-material/Favorite";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { likeNote, unlikeNote, upsertNote } from "../api/pbp-user";
import { MAX_POST_SIZE_PX } from '../common/css-constants';
import { useAuth } from "../context/auth-context";
import { useDataContext } from "../context/nba-data";
import { League, NotePrivacy } from "../types/constants";
import { noteDto, pbpVideoDto } from "../types/dto";
import { games } from "../types/ui";
import ClipPlaylistViewWrapper from "./ClipPlaylistViewWrapper";
import GameInfo from "./GameInfo";
import NotePrivacySelect from "./NotePrivacySelect";
import NoteText from "./NoteText";
import SavePlaylistButton from "./SavePlaylistButton";
import ShareMenu from "./ShareMenu";
import VideoPlayer from "./VideoPlayer";
import { createClipPath, createClipPathExternal } from '../utils/url-utils';
import CommentIcon from '@mui/icons-material/Comment';
import { LikeIcon } from './LikeIcon';
import ReplyIcon from '@mui/icons-material/Reply';
import ReplyModal from './ReplyModal';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../common/theme';

interface clipViewProps {
  note: noteDto;
  pbpVideo: pbpVideoDto;
  isSingleItemView: boolean;
}
export default function ClipView(props: clipViewProps) {
  const [note, setNote] = useState(props.note);
  const pbpVideo = props.pbpVideo;
  const [noteText, _setNoteText] = useState(note.note);
  const [notePrivacy, _setNotePrivacy] = useState(note.notePrivacy as NotePrivacy);
  const [isEdited, setIsEdited] = useState(false);
  const [userLiked, setUserLiked] = useState(note.userLiked);
  const userAuth = useAuth();
  const userId = userAuth?.userId ?? null;
  const userIsAuthor = userId === note.userId;

  useEffect(() => setNote(props.note), [props.note]);

  const setNoteText = (newText: string) => {
    setIsEdited(true);
  };
  const setNotePrivacy = (newPrivacy: NotePrivacy) => {
    setIsEdited(true);
    _setNotePrivacy(newPrivacy);
  };

  const onSubmitNote = (e: React.MouseEvent) => {
    e.stopPropagation();
    upsertNote(
      userAuth.userId,
      League.NBA,
      note.seasonYear,
      pbpVideo.gameId,
      pbpVideo.pbpVideoId,
      noteText,
      notePrivacy,
    ).then(res => {
      setNote({ ...note, note: noteText, notePrivacy });
      setIsEdited(false);
    });
  };

  const onLikeClick = () => {
    if (userId) {
      if (!userLiked) {
        likeNote(note.userId, pbpVideo.pbpVideoId, null);
        setUserLiked(true);
      } else {
        unlikeNote(note.userId, pbpVideo.pbpVideoId);
        setUserLiked(false);
      }
    }
  };

  const userLoggedIn = userAuth.userId !== null;
  const data = useDataContext();

  const allGames = data.gamesBySeason[note.seasonYear];
  const game = allGames.find((game) => game.gameId === pbpVideo.gameId);

  let likesAdjust = 0;
  if (userLiked && userLiked !== note.userLiked) likesAdjust++;
  else if (!userLiked && userLiked !== note.userLiked) likesAdjust--;

  const greaterThanSmall = useMediaQuery(theme.breakpoints.up("sm"));
  
  return (
    <ClipPlaylistViewWrapper
      viewItemUrl={props.isSingleItemView ? null : createClipPath(
        note.userId,
        pbpVideo.pbpVideoId,
      )}
      username={note.username}
      ownerUserId={note.userId}
      userFollows={note.userFollows}
      userCanFollow={note.userCanFollow}
      itemDate={note.noteUpdateUtc}
    >
      <>
        <CardContent sx={{ pb: 1, pt: 0, pl: 0, pr: 0 }}>
          <VideoPlayer
            autoplay={true}
            pauseTrigger={false}
            onEnded={() => { }}
            pbpVideoId={pbpVideo.pbpVideoId}
            videoUrl={pbpVideo.videoUrl}
            videoHeight={0}
            onVideoLoad={() => { }}
            onView={() => { }}
          />
          <Box style={{ textAlign: "center" }}>
            <GameInfo
              showOverflow={true}
              pbp={pbpVideo}
              game={game as games}
              showClock={true}
              showDate={true}
            />
          </Box>
          {
            (userIsAuthor || noteText?.trim()) && <Box style={{ textAlign: "center", marginTop: '10px' }}>
              <NoteText
                isEmbed={false}
                isPlaylist={false}
                userLoggedIn={true}
                userIsAuthor={userIsAuthor}
                noteVal={noteText}
                onChange={setNoteText}
              />
            </Box>
          }
        </CardContent>
        <CardActions sx={{ fontSize: "1.2rem", padding: 0, maxWidth: MAX_POST_SIZE_PX, margin: 'auto' }}>
          <Grid container flexDirection={greaterThanSmall ? 'row-reverse' : 'row'}>
            {
              userIsAuthor &&
              <Grid item xs={12} sm={4} md={3} textAlign={'right'} >
                <NotePrivacySelect
                  privacy={notePrivacy}
                  onChange={privacy => setNotePrivacy(privacy)}
                />
                <Button
                  sx={{ visibility: userIsAuthor ? "" : "hidden" }}
                  disabled={!isEdited}
                  variant="contained"
                  onClick={(e) => onSubmitNote(e)}
                >
                  Edit
                </Button>
              </Grid>
            }
            <Grid item xs={12} sm={userIsAuthor ? 8 : 12} md={userIsAuthor ? 9 : 12}>
              <Grid container sx={{ justifyContent: "flex-end" }} gap="15px">
                {/* <IconButton aria-label="more" sx={{ fontSize: "1.2rem" }}>
                <MoreHorizIcon sx={{ fontSize: "inherit" }} />
              </IconButton> */}
                {
                  userLoggedIn && pbpVideo && <SavePlaylistButton pbp={pbpVideo}
                    button={
                      <IconButton
                        sx={{ fontSize: "1.2rem" }}
                      >
                        <AddIcon  sx={{ fontSize: "inherit" }} />
                        <Typography component='span' variant='body1' >
                        &nbsp;Playlist
                      </Typography>
                      </IconButton>
                    } />
                }
                {
                  <ReplyModal
                    content={note.note}
                    userId={note.userId}
                    username={note.username}
                    date={note.noteUpdateUtc}
                    pbpVideoId={note.pbpVideoId}
                    parentReplyId={null}
                    rootReplyId={null}
                    onReply={() => { }}
                    button={<IconButton
                      size="small"
                    >
                      <ReplyIcon sx={{ fontSize: "inherit" }} />
                      <Typography component='span' variant='body1' >
                        &nbsp;Reply
                      </Typography>
                    </IconButton>}
                  />
                }
                <IconButton
                  size="small"
                ><CommentIcon sx={{ fontSize: "inherit" }} />
                  <Typography component='span' variant='body1' >
                    &nbsp;{note.replies}
                  </Typography>
                </IconButton>
                <LikeIcon userLiked={userLiked} onLikeClick={onLikeClick} likesCount={note.likes + likesAdjust} />
                {/* <ShareMenu
                  playlistOrNoteCheck={note}
                  note={note}
                  playlist={undefined}
                  pbpVideo={pbpVideo}
                  playlistPbpVideos={[]}
                /> */}
              </Grid>
            </Grid>
          </Grid>
        </CardActions>
      </>
    </ClipPlaylistViewWrapper>
  );
}
