import FavoriteIcon from "@mui/icons-material/Favorite";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from '@mui/material/CardHeader';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { getPlaylistDetails } from '../api/playlist';
import { MAX_POST_SIZE_PX } from '../common/css-constants';
import NoteText from "../components/NoteText";
import VideoPager from '../components/VideoPager';
import VideoPlayer from "../components/VideoPlayer";
import { noteDto, notesById, pbpVideoDto, playlistHeavyDto } from "../types/dto";
import {
    createPlaylistPathExternal, createUserProfilePath
} from "../utils/url-utils";
import CommentIcon from '@mui/icons-material/Comment';

const shouldSetVideoIndex = (index: number, pbp: any[]) => {
    if (index >= 0 && index <= pbp.length - 1) return true;
    return false;
};



interface playlistClipViewProps {
    playlist: playlistHeavyDto;
    notesById: notesById;
    pbpVideoById: { [key: number]: pbpVideoDto };
    loadPlaylistDetails: boolean;
    startingPbpVideoId: number | null;
    watchOnCsLink: string;
}

export default function PlaylistClipView(props: playlistClipViewProps) {
    const [isInitialVideo, setIsInitialVideo] = useState(true);
    const { playlist } = props;
    let startIndex = 0;
    if (props.startingPbpVideoId) {
        const i = playlist.playlistItems.findIndex(x => x.pbpVideoId === props.startingPbpVideoId);
        if (i > 0) startIndex = i;
    }
    const [videoIndex, _setVideoIndex] = useState(startIndex);
    const [videoIndexDisplay, _setVideoIndexDisplay] = useState(0);

    const [notesById, setNotesById] = useState(props.notesById);
    const [pbpVideoById, setPbpVideoById] = useState(props.pbpVideoById);
    const vid = playlist.playlistItems[videoIndex];
    const note = notesById[vid.pbpVideoId] as noteDto | undefined;
    const pbpVideo = pbpVideoById[vid.pbpVideoId] as pbpVideoDto | undefined;
    const playlistPbpVideos = playlist.playlistItems.map(x => pbpVideoById[x.pbpVideoId]).filter(x => x);

    const [noteText, _setNoteText] = useState(note?.note ?? "");

    const setNoteInfo = (toSet: noteDto | undefined) => {
        _setNoteText(toSet?.note ?? "");
    }
    const setVideoIndex = (i: number) => {
        _setVideoIndex(i);
        _setVideoIndexDisplay(i);
        const newItem = playlist.playlistItems[i];
        const note = notesById[newItem.pbpVideoId];
        setNoteInfo(note);
    };

    useEffect(() => {
        if (props.loadPlaylistDetails) {
            getPlaylistDetails(props.playlist.playlistId).then(res => {
                if (res) {
                    setPbpVideoById(res.pbpVideoById);
                    setNotesById(res.notesById);
                    const note = res.notesById[vid.pbpVideoId] as noteDto | undefined;
                    setNoteInfo(note);
                }
            })
        }
    }, []);
    const handleVideoChange = (i: number) => {
        if (shouldSetVideoIndex(i, playlist.playlistItems)) {
            setVideoIndex(i);
            setIsInitialVideo(false);
        }
    };
    const prevVideo = () => handleVideoChange(videoIndex - 1);
    const nextVideo = () => handleVideoChange(videoIndex + 1);

    const playlistPath = createPlaylistPathExternal(
        playlist.playlistId,
        vid.pbpVideoId
    );

    useEffect(() => {
    }, [videoIndex])
    // const data = useDataContext();
    // let game: games | undefined;
    // if (pbpVideo) {
    //     const allGames = data.gamesBySeason[pbpVideo.season];
    //     game = allGames.find((game) => game.gameId === pbpVideo.gameId);
    // }


    const userProfilePath = createUserProfilePath(playlist.userId);
    const dt = note?.noteUpdateUtc ? DateTime.fromJSDate(new Date(note?.noteUpdateUtc)).toRelative() : null;
    const border = "1px solid grey";


    return (
        <Card
            sx={{
                // maxWidth: calculateMaxVideoSize(),
                padding: '10px',
                // margin: "10px",
                // background: "none",
                // borderBottom: border,
                borderRadius: "5px",
            }}
        >
            <CardHeader
                sx={{ pl: '10px', pr: '10px', pt: "10px", pb: "10px" }}
                // avatar={
                //   <Link
                //     to={userProfilePath}
                //     style={{ color: "white", textDecoration: "none" }}
                //   >
                //     {/* <Avatar>{note.username}</Avatar> */}
                //   </Link>
                // }
                title={
                    <Grid container sx={{ justifyContent: "space-between" }}>
                        <Typography variant="body1" sx={{ verticalAlign: "middle" }}>
                            <Link to={userProfilePath} target='_blank' style={{ color: 'black' }}>
                                {playlist.username}
                            </Link>
                            &nbsp;&nbsp;
                            <Link to={props.watchOnCsLink} target='_blank' style={{ textDecoration: 'none', color: 'black' }}>
                                <Typography variant="caption" >
                                    {dt}
                                </Typography>
                            </Link>
                        </Typography>
                        <Link to={props.watchOnCsLink} target='_blank' style={{ flexGrow: 1, textDecoration: 'none', color: 'black' }}>
                            <Box sx={{ textAlign: 'right' }}>
                                <Typography variant="body1" component='span' sx={{ fontSize: '1.1rem', lineHeight: '25px', verticalAlign: "top" }}>
                                    Watch on&nbsp;
                                </Typography>
                                <Box
                                    component="img"
                                    sx={{
                                        height: 25,
                                        // width: 350,
                                        // maxHeight: { xs: 233, md: 167 },
                                        // maxWidth: { xs: 170, md: 350 },
                                    }}
                                    src="/ICON_TRANSPARENT.png"
                                />
                            </Box>
                        </Link>
                    </Grid>
                }
            />
            <>
                <CardContent sx={{ pb: 1, pt: 0, pl: 0, pr: 0 }}>
                    <Box marginX='10px' marginBottom='5px' textAlign='left'>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                            {playlist.title}
                        </Typography>
                        <Typography variant='body2'>
                            {playlist.note}
                        </Typography>
                    </Box>

                    <VideoPlayer
                        autoplay={!isInitialVideo}
                        pauseTrigger={false}
                        onEnded={nextVideo}
                        pbpVideoId={vid.pbpVideoId}
                        videoUrl={vid.url}
                        videoHeight={0}
                        onVideoLoad={() => { window.dispatchEvent(new Event('resize')) }}
                        onView={() => { }}
                    />
                    <Grid container marginBottom='15px'>
                        <Grid item xs={6} textAlign='center' alignSelf='center'>
                            <VideoPager isPlaylist={true} videoIndex={videoIndex} maxIndex={playlist.playlistItems.length} onVideoIndexChange={handleVideoChange} />
                        </Grid>
                        <Grid item xs={3} paddingX='2px'>
                            <Button sx={{ paddingY: 0 }} onClick={prevVideo} disabled={videoIndex < 1} variant="contained" fullWidth>
                                <SkipPreviousIcon />
                                Prev
                            </Button>
                        </Grid>
                        <Grid item xs={3} paddingX='2px'>
                            <Button sx={{ paddingY: 0 }} onClick={nextVideo} disabled={videoIndex >= playlist.playlistItems.length - 1} variant="contained" fullWidth>
                                Next
                                <SkipNextIcon />
                            </Button>
                        </Grid>
                    </Grid>
                    {/* {
                        pbpVideo && <Box style={{ textAlign: "center" }}>
                            <GameInfo
                                showOverflow={true}
                                pbp={pbpVideo}
                                game={game as games}
                                showClock={true}
                                showDate={true}
                            />
                        </Box>
                    } */}
                    {
                        (noteText?.trim()) &&
                        <Box style={{ marginTop: '10px', textAlign: 'center' }}>
                            <NoteText
                                isEmbed={true}
                                isPlaylist={false}
                                userLoggedIn={true}
                                userIsAuthor={false}
                                noteVal={noteText}
                                onChange={() => { }}
                            />
                        </Box>
                    }
                </CardContent>
                <CardActions sx={{ fontSize: "1.2rem", padding: 0 }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container sx={{ justifyContent: "flex-end", maxWidth: MAX_POST_SIZE_PX, margin: 'auto' }} gap="15px">
                                {
                                    (note !== undefined) &&
                                    <>
                                        {
                                            note?.note &&
                                            <Link to={props.watchOnCsLink} target='_blank'>
                                                <IconButton
                                                    aria-label="Favorite"
                                                    sx={{ color: "tomato" }}
                                                    size="small"
                                                    onClick={() => { }}
                                                ><CommentIcon sx={{ fontSize: "inherit" }} />
                                                    &nbsp;{note.replies}
                                                </IconButton>
                                            </Link>
                                        }
                                        {
                                            note?.note &&
                                            <Link to={props.watchOnCsLink} target='_blank'>
                                                <IconButton
                                                    aria-label="Favorite"
                                                    sx={{ color: "tomato" }}
                                                    size="small"
                                                    onClick={() => { }}
                                                ><FavoriteIcon sx={{ fontSize: "inherit" }} />
                                                    &nbsp;{note.likes}
                                                </IconButton>
                                            </Link>
                                        }
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </CardActions>
            </>
        </Card>
    );

}