import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { getGameVideo, getPlayerVideo } from "../api/pbp";
import VideoList from "../components/VideoList";
import { useAuth } from "../context/auth-context";
import { allPlayerPlayTypes, Comparers, League, Season, SortType, validSortTypes } from "../types/constants";
import {
  pbpVideoLineups
} from "../types/dto";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { calculateMaxVideoSize } from "../common/css-constants";
interface playerVideoProps {}

export default function PlayerVideo(props: playerVideoProps) {
  const [gamesPbp, setGamesPbp] = useState({} as pbpVideoLineups);
  const [searchParams, setSearchParams] = useSearchParams();
  const [playerId, setPlayerId] = useState(
    searchParams.get("playerId") ? Number(searchParams.get("playerId")) : null,
  );
const [apiInFlight, setApiInFlight] = useState(false); 
  const fetchData = () => {
    const seasonQs = searchParams.get("season") as Season;
    const playerIdQsRaw = searchParams.get("playerId");
    const playerIdQs = Number(playerIdQsRaw);

    const playTypesQs = searchParams.get('playTypes');
    const playTypes = playTypesQs?.split(',') ?? [];
    const shotTypesQs = searchParams.get('shotTypes');
    const shotTypesSplit = shotTypesQs?.split(',') ?? [];
    const shotTypes = shotTypesSplit.map(x => Number(x)).filter(x => x);
    const teamIdQs = searchParams.get('teamId');
    const teamId = Number(teamIdQs)
    const gameIdsQs = searchParams.get('gameIds');
    const gameIds = gameIdsQs?.split(',').map(x => Number(x)) ?? [];
    const lineupPlayerIdsQs = searchParams.get('lineupPlayerIds');
    const lineupPlayerIds = lineupPlayerIdsQs?.split(',').map(x => Number(x)) ?? [];
    const skipQsFetch = searchParams.get('skip');
    const skipCountFetch = Number(skipQsFetch) || 0;
    const sort = searchParams.get('sort') as SortType;
    const sortToUse = validSortTypes.has(sort) ? sort : SortType.ASC;
    setPlayerId(playerIdQs);
    setApiInFlight(true);
    if (seasonQs !== null && playerIdQsRaw !== null && teamIdQs !== null)
      getPlayerVideo(League.NBA, seasonQs, playerIdQs, teamId, 
        playTypes, shotTypes, gameIds, lineupPlayerIds,  skipCountFetch, sortToUse).then((x) => setGamesPbp(x))
          .finally(() => setApiInFlight(false));

    // TODO this needs to also reset your workspace state
  };
  useEffect(fetchData, [useLocation()]);
  const skipQs = searchParams.get('skip');
  const skipCount = Number(skipQs) || 0;

  return (
    apiInFlight ? <div>Searching...</div> :
    gamesPbp.pbpVideo?.length === 0 ? <div>No videos available</div> :
      <>
        <Grid container maxWidth={calculateMaxVideoSize()} margin='auto' marginTop='10px'>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                searchParams.set('skip', Math.max(skipCount-500, 0).toString());
                setSearchParams(searchParams);
              }}
              disabled={apiInFlight || skipCount === 0}
              variant="contained" fullWidth>
              <ArrowBackIosIcon />
              Last 500 Clips
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                searchParams.set('skip', (skipCount + 500).toString());
                setSearchParams(searchParams);
              }}
              disabled={apiInFlight || (gamesPbp.pbpVideo?.length ?? 0) < 500}
              variant="contained" fullWidth>
              Next 500 Clips
              <ArrowForwardIosIcon />
            </Button>
          </Grid>
        </Grid>
        <VideoList
          gamesPbp={gamesPbp}
        />

      </>
  );
}
