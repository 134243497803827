import Grid from "@mui/material/Grid";
import { replyDto } from "../types/dto";
import { LikeIcon } from "./LikeIcon";
import  ReplyIcon  from "@mui/icons-material/Reply";
import UserPostHeader from "./UserPostHeader";
import Typography from "@mui/material/Typography";
import ReplyModal from "./ReplyModal";
import CommentIcon from "@mui/icons-material/Comment";
import IconButton from '@mui/material/IconButton';
import Box from "@mui/material/Box";
import { likeReply, unlikeReply } from "../api/reply";
import { useEffect, useState } from "react";
import { useAuth } from "../context/auth-context";

interface replyProps {
    highlightReply: boolean;
    reply: replyDto;
    pbpVideoId: number;
    onReply: (reply: replyDto) => void;
}

export default function Reply(props: replyProps) {
    const { reply } = props;
    const [userLiked, setUserLiked] = useState(reply.userLiked);
    const userAuth = useAuth();
    const userId = userAuth?.userId ?? null;

    const onLikeClick = () => {
        if (userId) {
            if (!userLiked) {
                likeReply(reply.replyUserId, reply.replyId);
                setUserLiked(true);
            } else {
                unlikeReply(reply.replyUserId, reply.replyId);
                setUserLiked(false);
            }
        }
    };

    useEffect(() => setUserLiked(props.reply.userLiked), [props.reply]);
    let likesAdjust = 0;
    if (userLiked && userLiked !== reply.userLiked) likesAdjust++;
    else if (!userLiked && userLiked !== reply.userLiked) likesAdjust--;
    return <Grid container sx={{ backgroundColor: props.highlightReply ? '#404040' : '#202020', paddingX: '10px', paddingY: '5px', borderRadius: '8px' }}>
        <Grid item xs={12} marginBottom='5px'>
            <UserPostHeader username={reply.replyUsername} userId={reply.replyUserId} itemDate={reply.createdUtc} />
        </Grid>
        <Grid item xs={12} marginBottom='8px'>
            <Typography variant='body1' paddingBottom='0px'>
                {reply.note}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            {/* <Box sx= {{display: 'inline-block', borderTop: '1px solid gray'}}> */}
            <ReplyModal
                content={reply.note}
                userId={reply.replyUserId}
                username={reply.replyUsername}
                date={reply.createdUtc}
                pbpVideoId={props.pbpVideoId}
                parentReplyId={reply.replyId}
                rootReplyId={reply.rootReplyId ?? reply.replyId}
                onReply={props.onReply}
                // idk why i can't refactor this into its own component
                button={
                    <IconButton
                        aria-label="Favorite"
                        size="small">
                        <ReplyIcon sx={{ fontSize: "inherit" }} />
                        <Typography component='span' variant='body1' >
                            Reply
                        </Typography>
                    </IconButton>
                }
            />
            {/* </Box> */}

            <LikeIcon userLiked={userLiked} onLikeClick={onLikeClick} likesCount={reply.likes + likesAdjust} />
        </Grid>
    </Grid>
}