import Typography from "@mui/material/Typography";
import { useDebounce } from "../utils/debounce-utils";
import TextField from "@mui/material/TextField";
import { useEffect, useMemo, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import SearchIcon from '@mui/icons-material/Search';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { drawerWidth } from "./SituationMenu";
import { pbpVideoDto } from "../types/dto";
import GameInfo from "./GameInfo";
import { useDataContext } from "../context/nba-data";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import InputAdornment from "@mui/material/InputAdornment";
import { searchGameQueryPart } from "../utils/game-utils";
import { games } from "../types/ui";

interface searchMenuProps {
    pbpVideos: pbpVideoDto[],
    videoIndex: number,
    onOpenDrawer: () => void,
    onVideoIndexChange: (newIndex: number) => void,
}

export default function SearchMenu(props: searchMenuProps) {
    const virtuoso = useRef<VirtuosoHandle | null>(null);
    useEffect(() => {
        virtuoso?.current?.scrollToIndex({
            index: props.videoIndex,
        });
    }, [props.videoIndex])
    const [open, _setOpen] = useState(false);
    const setOpen = (newVal: boolean) => {
        _setOpen(newVal);
        if (newVal) drawerRef.current?.focus();
    };
    const drawerRef = useRef<HTMLDivElement>(null);

    const toggleDrawer = () => {
        if (!open) props.onOpenDrawer();
        setOpen(!open);
    };
    const [userSearch, setUserSearch] = useState("");

    const data = useDataContext();

    const filteredVideos = useMemo(() => {
        return props.pbpVideos.filter(pbpVideo => {
            // const pbpVideo = playlistNotes.pbpVideoById[item.pbpVideoId];
            // const note = playlistNotes.notesById[item.pbpVideoId] as noteDto | undefined;
            const curGame = data.gamesBySeason[pbpVideo.season].find(
                (x) => x.gameId === pbpVideo.gameId,
            ) as games;
            const teamsById = data.teams;
            if (userSearch.length < 2) return true;
            else {
                const splitQuery = userSearch.split(/[\s*]+/).map((x) => x.toLocaleLowerCase());
                return splitQuery.every(queryPart => pbpVideo.playDesc.toLocaleLowerCase().includes(queryPart) ||
                  searchGameQueryPart(curGame, teamsById, queryPart)
                );
            }
        });
    }, [userSearch, props.pbpVideos]);
    const drawerContent = (
        <>
            <Toolbar
                sx={{
                    position: "flex",
                    width: "100%",
                    justifyContent: "center",
                    marginRight: "auto",
                }}
            >
                <Typography variant="h6" sx={{ width: '100%', display: 'block' }}>
                    Search Plays
                </Typography>
                <IconButton
                    onClick={toggleDrawer}
                    sx={{ position: "absolute", right: 5, top: 5 }}
                >
                    <Close />
                </IconButton>
            </Toolbar>
            <TextField
                sx={{ margin: 'auto', width: '300px', marginBottom: '10px' }}
                // onKeyDown={stopPropagation}
                value={userSearch}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUserSearch(event.target.value);
                }}
                placeholder='Search'
                InputProps={{
                    sx: { paddingLeft: '6px' },
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon sx={{ paddingLeft: '0px' }} />
                        </InputAdornment>
                    ),
                }}
                color='secondary'
                size='small'
                variant="outlined"
            />
            <List>
                <Virtuoso
                    ref={virtuoso}
                    data={filteredVideos}
                    style={{
                        minHeight: `65vh`,
                        maxHeight: `75vh`,
                        margin: "auto",
                    }}
                    itemContent={(i, p) => {
                        const curGame = p?.season ? data.gamesBySeason[p.season].find(
                            (x) => x.gameId === p.gameId,
                        ) : undefined;
                        return <>
                            <ListItem key={p.pbpVideoId} disablePadding sx={{ border: '1px solid rgba(255, 255, 255, 0.12)' }}>
                                <ListItemButton sx={{ padding: '0px', backgroundColor: props.videoIndex !== i ? '#1d1d1d' : 'inherit' }}
                                    onClick={() => {
                                        setOpen(false);
                                        const index = props.pbpVideos.findIndex(x => x.pbpVideoId === p.pbpVideoId);
                                        props.onVideoIndexChange(index);
                                    }}
                                >
                                    <Grid
                                        container
                                        justifyContent="center"
                                        sx={{ textAlign: "center", padding: "10px", rowGap: "10px" }}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{ textAlign: "center", }}
                                        >
                                            {
                                                curGame && <GameInfo
                                                    showOverflow={true}
                                                    pbp={p}
                                                    game={curGame}
                                                    showClock={true}
                                                    showDate={false}
                                                />
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    marginTop: '8px',
                                                    fontSize: { xs: "0.8rem", sm: "0.875rem", md: "0.95rem" },
                                                    textAlign: "center",
                                                    pl: { xs: 0, sm: "10px" },
                                                }}
                                            >
                                                {p.playDesc}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                        </>;
                    }}
                />
            </List>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    onClick={toggleDrawer}
                    color="secondary"
                    fullWidth
                >
                    Close
                </Button>
            </Grid>
        </>
    );
    const theme = useTheme();
    const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
    return (
        <>
            <Button
                variant="outlined"
                onClick={toggleDrawer}
                color="secondary"
                fullWidth
                sx={{ paddingX: 0 }}
                TouchRippleProps={{ style: { width: "100%" } }}
            >
                Search</Button>
            <Drawer
                ref={drawerRef}
                // onBlur={() => setOpen(false)} doesn't work
                sx={{
                    "& .MuiDrawer-paper": {
                        zIndex: 1202, // TODO fix this
                        margin: "auto",
                        marginBottom: "10px",
                        maxWidth: drawerWidth,
                        boxSizing: "border-box",
                        padding: '10px',
                        backgroundColor: "#343434",
                    },
                }}
                anchor={greaterThanMid ? "top" : "bottom"}
                variant="persistent"
                open={open}
            >
                {drawerContent}
            </Drawer>
        </>
    );
}