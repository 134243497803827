import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchIcon from '@mui/icons-material/Search';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { deletePlay, getPlaylistDetails, reorderPlaylist } from '../api/playlist';
import { textOverflowEllipsisSx } from "../common/css-constants";
import { useAuth } from "../context/auth-context";
import { useDataContext } from '../context/nba-data';
import { NotePrivacy } from "../types/constants";
import { noteDto, playlistNotes } from "../types/dto";
import { games } from '../types/ui';
import { searchGameQueryPart } from "../utils/game-utils";
import PlaylistItemView from "../components/PlaylistItemView";
import { USER_PLAYLISTS_PATH } from "../utils/url-utils";
import ReorderPlaylistModal from "../components/ReorderPlaylistModal";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
    TouchSensor,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { isPrimaryInputTouch } from "../utils/input-utils";

interface playlistItemsProps {
}
const menuItemPaddingX = '5px;';
const menuItemPaddingY = '3px;';
export default function PlaylistItems(props: playlistItemsProps) {
    const [selectedIndex, setSelectedIndex] = useState(null as null | number);
    const [loaded, setLoaded] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [playlistNotes, setPlaylistNotes] = useState(null as playlistNotes | null);
    const playlistId = searchParams.get("playlistId");
    const [userSearch, setUserSearch] = useState("");
    const [title, setTitle] = useState("");
    const [privacy, setPrivacy] = useState(NotePrivacy.Public);
    const [note, setNote] = useState("");
    const userAuth = useAuth();
    const playsListRef = useRef<HTMLDivElement>(null);
    const [distance, setDistance] = useState(0);
    const [reorderInFlight, setReorderInFlight] = useState(false);

    const isTouch = isPrimaryInputTouch();
    const primarySensor = isTouch ? TouchSensor : PointerSensor;
    const activationConstraint = isTouch ? {
        delay: 50,
        tolerance: 5
    } : {
        distance: 5
    };
    const sensors = useSensors(
        useSensor(primarySensor, { activationConstraint }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    useEffect(() => {
        const element = playsListRef.current;
        if (element) {
            const rect = element.getBoundingClientRect();
            const distanceToViewportTop = rect.top + window.scrollY;
            setDistance(distanceToViewportTop);
        }
    }, [playlistNotes]);

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openMenuIndex, setOpenMenuIndex] = useState<null | number>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, i: number) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpenMenuIndex(i);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setOpenMenuIndex(null);
    };
    const navigate = useNavigate();

    const onBack = () => {
        handleMenuClose();
        return navigate(USER_PLAYLISTS_PATH)
    }
    const getSetPlaylistDetails = () => {
        if (playlistId) {
            getPlaylistDetails(playlistId).then(details => {
                if (details) {
                    setPlaylistNotes(details);
                    setTitle(details.playlist.title);
                    setNote(details.playlist.note);
                    setPrivacy(details.playlist.privacy);
                    setLoaded(true);
                    if (details.playlist.playlistItems.length > 0) setSelectedIndex(0);
                }
            });
        } else {
            // TODO raise error
        }
    }

    const onDeleteClick = async (event: React.MouseEvent<HTMLLIElement>, playlistId: string, pbpVideoId: number) => {
        event.stopPropagation();
        handleMenuClose();
        await deletePlay(playlistId, pbpVideoId);
        await getSetPlaylistDetails();
        return true;
    }


    useEffect(() => {
        if (playlistId) {
            getSetPlaylistDetails();
        } else {
            setLoaded(true);
        }
    }, [playlistId]);

    const data = useDataContext();


    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (!reorderInFlight && active.id !== over?.id) {
            setReorderInFlight(true);
            const oldPlaylistItems = playlistNotes?.playlist.playlistItems;
            if (oldPlaylistItems) {
                const oldIndex = oldPlaylistItems.findIndex(x => x.pbpVideoId === active.id);
                const newIndex = oldPlaylistItems.findIndex(x => x.pbpVideoId === over?.id);
                const playlistItems = arrayMove(playlistNotes.playlist.playlistItems, oldIndex, newIndex);
                const toSet = { ...playlistNotes, playlist: { ...playlistNotes.playlist, playlistItems } };
                reorderPlaylist(playlistId as string, playlistItems.map(x => x.pbpVideoId))
                    .then(() => setPlaylistNotes(toSet))
                    .finally(() => setReorderInFlight(false));
            } else {
                setReorderInFlight(false);
            }
        }
    }


    const key = selectedIndex !== null ?
        playlistNotes?.playlist?.playlistItems[selectedIndex]?.pbpVideoId : null;


    const filteredPlaylist = useMemo(() => {
        return playlistNotes === null ? [] :
            playlistNotes.playlist.playlistItems.filter(item => {
                const pbpVideo = playlistNotes.pbpVideoById[item.pbpVideoId];
                const note = playlistNotes.notesById[item.pbpVideoId] as noteDto | undefined;
                const curGame = data.gamesBySeason[pbpVideo.season].find(
                    (x) => x.gameId === pbpVideo.gameId,
                ) as games;
                const teamsById = data.teams;
                if (userSearch.length < 3) return true;
                else {
                    const splitQuery = userSearch.split(/[\s*]+/).map((x) => x.toLocaleLowerCase());
                    return splitQuery.every(queryPart => pbpVideo.playDesc.toLocaleLowerCase().includes(queryPart) ||
                        searchGameQueryPart(curGame, teamsById, queryPart)
                    );
                }
            });
    }, [userSearch, playlistNotes]);
    const userLoggedIn = userAuth.userId !== null;

    return (
        <Grid container textAlign={'center'}>
            {
                (!loaded || !playlistNotes) ?
                    <Typography variant='body1' sx={textOverflowEllipsisSx}>
                        <IconButton onClick={onBack} sx={{ marginRight: '5px' }}>
                            <ChevronLeftIcon />
                        </IconButton>
                        Loading...
                    </Typography> :
                    playlistNotes.playlist.playlistItems.length < 1 ?

                        <Typography variant='body1' sx={textOverflowEllipsisSx}>
                            <IconButton onClick={onBack} sx={{ marginRight: '5px' }}>
                                <ChevronLeftIcon />
                            </IconButton>
                            Playlist is empty
                        </Typography> :
                        <>
                            <Grid item xs={12}>
                                <Typography variant='body1' sx={textOverflowEllipsisSx}>
                                    <IconButton onClick={onBack} sx={{ marginRight: '5px' }}>
                                        <ChevronLeftIcon />
                                    </IconButton>
                                    {playlistNotes.playlist.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign='center'>
                                <TextField
                                    sx={{ minWidth: '300px', marginBottom: '10px' }}
                                    // onKeyDown={stopPropagation}
                                    value={userSearch}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setUserSearch(event.target.value);
                                    }}
                                    placeholder='Search plays'
                                    InputProps={{
                                        sx: { paddingLeft: '6px' },
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon sx={{ paddingLeft: '0px' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    color='secondary'
                                    size='small'
                                    variant="outlined"
                                />
                                </Grid>
                                <Grid item xs={12} textAlign='center'>
                                    <Typography variant="body1" marginBottom='8px'>Drag-and-drop to reorder</Typography>
                                </Grid>
                                <Grid item xs={12} textAlign='center'>

                                <DndContext
                                    sensors={sensors}
                                    collisionDetection={closestCenter}
                                    onDragEnd={handleDragEnd}
                                >
                                    <SortableContext
                                        items={filteredPlaylist.map(x => x.pbpVideoId)}
                                        strategy={verticalListSortingStrategy}
                                    >
                                        {
                                            filteredPlaylist.map((item, i) => {
                                                const pbpVideo = playlistNotes.pbpVideoById[item.pbpVideoId];
                                                const note = playlistNotes.notesById[item.pbpVideoId] as noteDto | undefined;
                                                const curGame = data.gamesBySeason[pbpVideo.season].find(
                                                    (x) => x.gameId === pbpVideo.gameId,
                                                ) as games;

                                                return <PlaylistItemView
                                                    key={pbpVideo.pbpVideoId}
                                                    playlistId={playlistId as string}
                                                    pbpVideo={pbpVideo}
                                                    note={note}
                                                    curGame={curGame}
                                                    onDelete={getSetPlaylistDetails}
                                                />
                                            })
                                        }
                                    </SortableContext>
                                </DndContext>
                            </Grid>
                            <Backdrop
                                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                                open={reorderInFlight}
                            >
                                <CircularProgress color="inherit" />&nbsp;&nbsp;Saving...
                            </Backdrop>
                        </>
            }
        </Grid>
    )
}
